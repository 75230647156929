import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ColorService, getFontFamily, tablet } from 'services';
import { Icon, IconsNames } from 'components/atoms';
import { PATHS } from 'router/config';

export const SaleOfferCard = memo(() => {
  const navigate = useNavigate();
  const onCardClick = () => {
    navigate(PATHS.courses, { replace: true });
  };

  return (
    <Wrapper onClick={onCardClick}>
      <Info>каталог курсов</Info>
      <Title>
        Выбрать курс <br /> со скидкой до 30%
      </Title>
      <IconStyled name={IconsNames.pumpkin} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
  background: linear-gradient(131.47deg, rgba(245, 79, 43, 0.9) 32.56%, rgba(255, 230, 40, 0.9) 100%),
  linear-gradient(0deg, #FFDA17, #FFDA17);

  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
  width: 100%;
  height: 380px;
  cursor: pointer;
  transition: 0.6s;
  overflow: hidden;
  margin-bottom: 64px;

  &:hover {
    box-shadow: 0 8px 20px rgba(100, 100, 100, 0.4);
  }

  ${tablet} {
    height: 332px;
    margin-bottom: 80px;
  }
}
`;

const Info = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  color: ${ColorService.WHITE};
  text-transform: uppercase;
  margin-bottom: 8px;
  z-index: 1;
`;

const Title = styled.div`
  max-width: 324px;
  z-index: 1;
  font-family: ${getFontFamily('bold')};
  font-size: 32px;
  line-height: 44px;
`;

const IconStyled = styled(Icon)`
  height: 73%;
  width: auto;
  object-fit: contain;
  object-position: right;
  align-self: flex-end;
  position: absolute;
  border-radius: 24px;
  right: 0;
  bottom: -20px;

  ${tablet} {
    height: 100%;
    right: 0;
    bottom: 0;
  }
`;
