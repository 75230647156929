import React, { memo, useRef } from 'react';

import styled from 'styled-components';

import {
  InfoCard,
  TMSFeatures,
  SaleOfferCard,
  StickyCounter,
  GiftBlock,
} from 'components/atoms';
import { Courses, MainForm } from 'components/molecules';

import {
  desktop,
  getFontFamily,
  tablet,
  ColorService,
  laptop_small,
} from 'services';

export const Main = memo(() => {
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Content>
        <MainInfoWrapper>
          <Title>Жутко выгодные скидки</Title>
          <Subtitle>
            Скидки до 30% на IT-курсы с 28 октября по 10 ноября
          </Subtitle>
          <Warning>
            Оплата в рассрочку до 18 мес / <Wrap /> Программы 2024 года
          </Warning>
          <GiftBlock />
        </MainInfoWrapper>

        <ContentForm ref={formRef}>
          <MainForm />
        </ContentForm>

        <SaleOfferCard />

        <Courses />
        <TMSFeatures />
      </Content>
      <FormWrapper>
        <MainForm />
        <InfoCard />
      </FormWrapper>

      <StickyCounter formRef={formRef} />
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${desktop} {
    flex-direction: row;
    gap: 32px;
  }
`;

const Content = styled.div``;

const MainInfoWrapper = styled.div`
  margin: 0 0 64px;

  ${tablet} {
    margin: 0 0 80px;
  }
`;

const Title = styled.h1`
  font-family: ${getFontFamily('bold')};
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 8px;

  ${tablet} {
    font-size: 64px;
    line-height: 76px;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.h2`
  font-family: ${getFontFamily('bold')};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;

  ${tablet} {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 32px;
  }
`;

const Warning = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 64px;
  padding: 8px 16px;
  border-radius: 500px;
  background-color: ${ColorService.BG_GRAY};
  font-family: ${getFontFamily('bold')};
  width: max-content;
  text-transform: uppercase;
  color: ${ColorService.ORANGE};

  ${tablet} {
    margin-bottom: 80px;
  }

  ${laptop_small} {
    margin-bottom: 64px;
  }
`;

const Wrap = styled.br`
  ${tablet} {
    display: none;
  }
`;

const FormWrapper = styled.div`
  display: none;

  ${desktop} {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    flex-direction: column;
    gap: 12px;
    top: 54px;
  }
`;

const ContentForm = styled.div`
  margin-bottom: 64px;

  ${tablet} {
    margin-bottom: 80px;
  }

  ${desktop} {
    display: none;
    margin-bottom: 0;
  }
`;
