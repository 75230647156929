import React, { memo } from 'react';

import styled from 'styled-components';

import { Icon, IconsNames } from 'components/atoms';
import { ColorService, getFontFamily, tablet } from 'services';

export const GiftBlock = memo(() => (
  <GiftBlockWrapper>
    <Icon name={IconsNames.gift_box} width={80} height={80} />

    <GiftText>
      <MainGiftText>+3 вводных мини-курса по основам IT в подарок</MainGiftText>
      <AddGiftText>
        <Dot>•</Dot> Знакомство с IT сферой <br />
        <Dot>•</Dot> Введение в дизайн <br />
        <Dot>•</Dot> Введение в разработку
      </AddGiftText>
    </GiftText>
  </GiftBlockWrapper>
));

const GiftBlockWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const GiftText = styled.div``;

const MainGiftText = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;

  ${tablet} {
    font-size: 18px;
    line-height: 28px;
  }
`;

const Dot = styled.span`
  ${tablet} {
    &:first-child {
      display: none;
    }
    & + br {
      display: none;
    }
  }
`;

const AddGiftText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${ColorService.WHITE};
`;
