import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, getFontFamily } from 'services';

interface ICourseProgramProps {
  hours: string;
  text: string;
  lessons: string[];
}

export const CourseProgram = memo(
  ({ hours, lessons, text }: ICourseProgramProps) => (
    <>
      <Hours>{hours}</Hours>
      <Text>{text}</Text>
      <LessonsWrapper>
        {lessons.map((lesson) => (
          <Lesson key={lesson}>{lesson}</Lesson>
        ))}
      </LessonsWrapper>
    </>
  ),
);

const Hours = styled.div`
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 18px;
  padding: 8px 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: ${ColorService.BG_GRAY};
  width: max-content;
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
`;

const LessonsWrapper = styled.ul``;

const Lesson = styled.li`
  font-family: ${getFontFamily('medium')};
  font-size: 18px;
  line-height: 24px;
  padding: 12px 0 16px;
  border-bottom: 1px solid ${ColorService.ADDITIONAL_GRAY};
`;
