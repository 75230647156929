import React, { memo } from 'react';

import styled from 'styled-components';
import {
  ColorService,
  desktop,
  getFontFamily,
  laptop_small,
  mobile_small,
  tablet,
} from 'services';
import { Icon, IconsNames } from 'components/atoms';

export const OurFeatures = memo(() => (
  <Wrapper>
    <FeatureItem>
      <Title>Принимаем платежи из любой точки мира</Title>
      <IconStyled name={IconsNames.pin_black} />
    </FeatureItem>
    <FeatureItem>
      <Title>Возможна оплата частями и от юр.лица</Title>
      <IconStyled name={IconsNames.wallet_black} />
    </FeatureItem>
    <FeatureItem>
      <Title>
        Готовим к собеседованиям, трудоустраиваем более 65% выпускников
      </Title>
      <IconStyled name={IconsNames.suitcase_black} />
    </FeatureItem>
  </Wrapper>
));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 24px;
  margin-top: 64px;

  ${tablet} {
    flex-direction: row;
    margin-top: 80px;
  }
`;

const FeatureItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 252px;
  background-color: ${ColorService.BG_GRAY};
  border-radius: 24px;
  padding: 40px 32px;

  ${tablet} {
    width: calc(50% - 12px);

    &:last-child {
      flex-basis: 100%;

      svg {
        width: 54%;
        height: 66%;
      }

      p {
        max-width: 300px;
      }
    }
  }

  ${desktop} {
    width: calc(33% - 14px);

    &:last-child {
      flex-basis: unset;

      svg {
        width: 100%;
        height: unset;
      }
    }
  }
`;

const Title = styled.p`
  z-index: 1;
  font-family: ${getFontFamily('bold')};
  font-size: 18px;
  line-height: 28px;
`;

const IconStyled = styled(Icon)`
  height: 60%;
  object-fit: contain;
  object-position: right;
  align-self: flex-end;
  position: absolute;
  border-radius: 24px;
  right: 0;
  bottom: 0;

  ${mobile_small} {
    height: 65%;
  }

  ${tablet} {
    height: 60%;
    right: 0;
  }

  ${desktop} {
    height: 50%;
  }

  ${laptop_small} {
    height: 60%;
  }
`;
